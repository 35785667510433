html.account body {
  overflow: auto;
}

.history-footer {
  color: #ababab;
  margin-bottom: -1em;
  padding-left: 0.4em;
  padding-top: 1.2em;
  font-size: 97%l
}