.facet-mention {
  text-decoration: none;
}

.facet-mention .account-avatar-chip {
  margin-right: 0;
  transform: scale(0.85) translateY(0.1em);
  filter: contrast(1.5) brightness(1.1);
  box-shadow: inset 1px 0.5px 2px #1919eea3;
}

.facet-mention:hover {
  text-decoration: underline;
}

.complete-post-content .post-notes-area .post-replies .reply-avatars .reply-avatar-marker.reply-avatar-marker-more {
  min-width: 2em;
  text-align: center;
  color: goldenrod;
  color: #2f5bab;
  font-style: italic;
}

.facet-url {
  text-decoration: none;
  color: rgb(6, 76, 197);
}

.facet-url:visited {
  color: rgb(136, 0, 178);
}

.facet-url:hover {
  text-decoration: underline;
}

.facet-url .link-text-before-domain {
  opacity: 0.4;
}

.facet-url .link-text-after-domain {
  opacity: 0.6;
}

.facet-url .unspecified-domain {
  color: rgb(165, 25, 0);
}

.facet-url .suspicious-link {
  color: rgb(255, 77, 92);
}


.search-match-highly-relevant {
  background: gold;
  border-radius: 0.3em;
  padding: 0 0.1em;
  margin: 0 -0.1em;
}

.search-match-relevant {
  background-image: linear-gradient(to top, #ffcd70, transparent 0.6em);
  border-radius: 0.2em;
}
