.search-animation {
  height: 30em;
  overflow: hidden;
  position: relative;
  margin-bottom: -5em;
}

.search-animation .flying-post {
  max-width: 18em;
  position: absolute;
  top: 2em;
}

.search-animation .flying-post .post-inner {
  zoom: 0.7;
}

.search-animation.search-animation-to-right .flying-post {
  left: -9em;
  animation: 6s forwards slideright;
}

.search-animation.search-animation-to-left .flying-post {
  right: -9em;
  animation: 6s forwards slideleft;
}

@keyframes slideright {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(200vw);
  }
}

@keyframes slideleft {
  from {
    transform: translateX(0) rotate(10deg);
    opacity: 0;
    filter: blur(3px);
  }

  20% {
    opacity: 1;
    filter: none;
  }

  70% {
    opacity: 1;
  }

  to {
    transform: translateX(-100vw) rotate(-10deg);
    opacity: 0;
  }
}