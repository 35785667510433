.thread-view .thread-reply-post {
  margin-top: -0.7em;
}

.thread-view .collapsed-thread-part {
  padding-left: 2em;
  margin: -0.8em 0;
}

.thread-view .collapsed-thread-part .collapsed-many-posts {
  display: grid;
  grid-template-columns: auto 1fr;
}

.thread-view .collapsed-thread-part .collapsed-many-posts .collapsed-many-posts-first,
.thread-view .collapsed-thread-part .collapsed-many-posts .collapsed-many-posts-rest,
.thread-view .collapsed-thread-part .collapsed-single-post,
.thread-view .collapsed-thread-part .collapsed-few-posts .collapsed-few-post-one
 {
  padding: 0.7em;
  padding-bottom: 1em;
  border: solid 1px #f7d69c;
  background: #fffcf2;
  box-shadow: 0px 0px 10px #0000001c;
  border-radius: 1.2em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.thread-view .collapsed-thread-part .account-avatar-chip {
  opacity: 0.6;
}

.thread-view .collapsed-thread-part .collapsed-many-posts .collapsed-many-posts-rest {
  justify-self: start;
  position: relative;
  left: -0.4em;
}

.thread-view .collapsed-thread-part .collapsed-single-post {
  display: inline-block;
}

.thread-view .collapsed-thread-part .collapsed-many-posts .collapsed-many-posts-rest .collapsed-many-posts-counter {
  padding-left: 0.3em;
  opacity: 0.5;
}

.thread-view .collapsed-thread-part .collapsed-few-posts .collapsed-few-post-one {
  display: inline-block;
  position: relative;
  margin-right: -0.4em;
}

.thread-conversation-view .post-date.post-timestamp-small-note {
  float: right;
  font-weight: 100;
  zoom: 0.9;
  transform: scaleY(1.1);
  color: #d3bc5c;
  text-decoration: none;
}

.thread-conversation-view .post-date.post-timestamp-small-note:hover {
  text-decoration: underline;
}

.thread-conversation-view .insignificant-expandable-markers {
  text-align:center;
  font-size: 80%;
}

.thread-conversation-view .complete-post-content .post-notes .insignificant-expandable-markers .account-avatar {
  display: block;
  text-align: right;
}

.thread-conversation-view hr.conversation-divider {
  color: #deb40f;
  background: linear-gradient(to right, transparent, currentColor, currentColor, transparent);
  height: 1px;
  border: none;
}

.thread-conversation-view .complete-post-content.conversation {
  margin-bottom: 1.2em;
}

.thread-conversation-view .conversation-after-tight-post {
  margin-top: 0.8em;
}

.thread-conversation-view .aside-interjection-section,
  .thread-forum-view .aside-interjection-section {
  padding: 1.5em;
  margin: 1.5em 0;
  margin-left: 1.5em;
  border: solid 1px #fdc3b5;
  border-radius: 1em;
  background: white;
  box-shadow: inset 2px 2px 7px #0000001f;
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
}

.complete-post-content .thread-post-expandos {
  padding: 1.5em;
  margin: 1.5em 0;
  margin-left: 1.5em;
  border: solid 1px #f3c97d;
  border-radius: 1em;
  background: rgb(255 254 250);
  box-shadow: inset 2px 2px 7px #0000001f;
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
}

.aside-interjection-section .bendy-line {
  width: 25%;
  margin-top: -1.5em;
  justify-self: start;
  position: relative;
  color: #e7af47;
  border-left: solid 1px currentColor;
  border-bottom: solid 1px currentColor;
  border-bottom-left-radius: 1em;
  display: flex;
  align-items: end;
  padding-left: 2em;
}

.complete-post-content .thread-post-expandos .bendy-line {
  color: #ffb5a3;
}

.aside-interjection-section .bendy-line::after {
  content: ' ';
  position: absolute;
  right: -1.5em;
  bottom: -1.6em;
  width: 1.5em;
  height: 1.6em;
  border-top: solid 1px currentColor;
  border-right: solid 1px currentColor;
  border-top-right-radius: 1em;
}

.aside-interjection-section .bendy-line .account-avatar {
  position: relative;
  top: 0.75em;
}

.insignificant-expandable-avatar-marker {
  cursor: pointer;
}